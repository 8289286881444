/* Customised styling */

.cta-content div a {
  margin: 10px 10px;
} 

.intro5-section {
  clip-path: polygon(0 0, 100% 0, 100%  90%, 0 100%);
}

p span {
  color: #20ad96;
  font-weight: 500;
}

.page-breadcrumb-no-title-section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 10px
}

.course-meta .title{
  margin-top: 40px;
  margin-bottom: 20px;
}

.lp-course-buttons{
  margin: 20px 0px !important;
}