.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Admi panel css */
.admin-container {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: '40';
  background-color: #f0f0f0;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 2;
}

.sidebar ul li {
  padding: 10px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
}

/* Add some basic global styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Navbar Styles */


.navbar h1 {
  margin: 0;
  font-size: 24px;
}

.navbar-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  text-decoration: none;

}

.navbar-links li {
  margin-left: 20px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
}

/* Adjustments for the admin-container to work with navbar */
.admin-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-with-sidebar {
  display: flex;
  flex-grow: 1;
}
.active-admin-link {
  color: #02b14b; /* Bootstrap primary color for example */
  font-size: 25px;
  text-decoration: none;
  font-weight: bold;
}

.admin-link {
  color: #000000; /* Bootstrap primary color for example */
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
}
 
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 2; /* Number of lines you want */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px; /* Adjust the width as per your requirement */
  line-height: 1.2em; /* Adjust based on your font size */
  height: 2.4em; /* This should be line-height multiplied by the number of lines */
}
